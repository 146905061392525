<template>
  <section>
    <div class="content-header">
      <h2>Supporting students with disabilities </h2>
    </div>
    <div class="content-wrapper">
      <p>An important part of your role is to recognize the unique needs and supports that may be helpful for students with disabilities</p>
      <p>Make sure you’re aware of the services available on your campus, so you can share that information with students who might need it.</p>
      <p>Check out <a :href="`${publicPath}docs/career/Roadmap-to-career-readiness - supporting students with disabilities.pdf`" download>Career Readiness for Students with Disabilities</a>, part of Centennial’s Career Resilience Toolkit (used with permission from Tracey Lloyd). </p>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '10',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
